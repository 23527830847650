<template>
  <fw-layout wide back-to="/manage" management mobile-ready>
    <template #main-content>
      <PanelManageAttendances />
    </template>
  </fw-layout>
</template>

<script>
import PanelManageAttendances from '@/components/panels/manage/PanelManageAttendances'

export default {
  components: {
    PanelManageAttendances
  }
}
</script>
