<template>
  <div class="overflow-auto relative" :style="{ width: componentWidth, height: componentHeight }">
    <div
      class="flex items-center gap-2 text-sm text-gray-500 font-semibold my-1 rounded-lg sticky top-0 bg-gray-50"
      style="z-index: 1;"
      :style="{ width: innerComponentWidth + 'px' }"
    >
      <div
        class="w-72 h-[38px] px-3 flex-shrink-0 sticky left-0 top-0 bg-gray-50 bg-opacity-80 flex items-center"
        style="z-index: 1;"
      >
        Pessoa
      </div>
      <div>
        <div ref="innerComponent" class="flex gap-2">
          <div
            v-for="(day, headerDayIdx) in tableDays"
            :key="`day_${headerDayIdx}`"
            :class="{
              'border border-primary': day === today
            }"
            class="w-16 text-center items-center justify-center flex flex-col py-1 px-0.5 rounded-lg bg-gray-50 bg-opacity-50"
          >
            <span>{{ day | formatLocaleDateString({ day: 'numeric' }) }}</span>
            <span class="text-xs opacity-70">{{ day | formatLocaleDateString({ month: 'short' }) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div :style="{ width: innerComponentWidth + 'px' }">
      <template v-for="(values, mapKey) in mapUsers">
        <div
          :key="`map_${mapKey}`"
          class="w-72 hover:bg-gray-200 flex items-center justify-center hover:bg-opacity-50 h-14 rounded-lg flex-shrink-0 sticky left-0 bg-gray-50 bg-opacity-80 z-20 font-medium text-sm"
        >
          {{ mapsbyKey[mapKey].title }}
        </div>
        <div
          v-for="mapUser in values"
          :key="`data_${mapKey}_${mapUser.user_key}`"
          class="flex hover:bg-gray-200 hover:bg-opacity-50 h-14 rounded-lg gap-2"
        >
          <div class="w-72 flex-shrink-0 sticky left-0 bg-gray-50 bg-opacity-80 z-20">
            <fw-person :person="users[mapUser.user_key]" size="xs" />
          </div>
          <div class="flex gap-2">
            <div
              v-for="(day, dayIndex) in tableDays"
              :key="`day_${dayIndex}`"
              :class="{
                'border border-primary': day === today
              }"
              :title="day"
              class="w-16 text-center flex items-center rounded-lg justify-center hover:border hover:border-gray-300"
            >
              <span
                v-if="!attendancesByDay[mapUser.user_key]?.[day]?.length"
                class="text-xs text-gray-500"
                title="Sem marcações para o dia"
              >
                -
              </span>
              <template v-else>
                <div
                  v-for="(attendance, index) in attendancesByDay[mapUser.user_key][day]"
                  :key="`item_${index}`"
                  :title="`Marcação em ${attendance.record_at}`"
                >
                  <fw-icon-arrow-down v-if="attendance.action == 'entry'" class="h-4 w-4 flex-shrink-0 text-primary" />
                  <fw-icon-arrow-up v-else class="h-4 w-4 flex-shrink-0 text-red-600" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import _ from 'lodash'

export default {
  name: 'PanelStickyTable',
  components: {},
  props: {
    startDate: {
      type: Date,
      default: null
    },
    endDate: {
      type: Date,
      default: null
    },
    attendances: {
      type: Object,
      default: () => {}
    },
    maps: {
      type: Array,
      default: () => []
    },
    users: {
      type: Object,
      default: () => {}
    },
    mapUsers: {
      type: Object,
      default: () => {}
    },
    tableParentRefName: {
      type: String
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    noVerticalScroll: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tableDays: [],
      //tableMonths: [],
      innerComponentWidth: 500,
      componentHeight: '100px',
      componentWidth: '100px'
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language ?? 'pt'
    },

    today() {
      return Dates.formatDateToAPI(new Date())
    },

    mapsbyKey() {
      return this.maps.reduce((acc, map) => {
        acc[map.key] = map
        return acc
      }, {})
    },

    attendancesByDay() {
      const res = {}

      for (const [userKey, value] of Object.entries(this.attendances)) {
        res[userKey] = _.groupBy(value, x => Dates.format(x.record_at, 'YYYY-MM-DD'))
      }

      return res
    }
  },

  watch: {
    startDate() {
      this.generateDates()
    },

    endDate() {
      this.generateDates()
    }
  },

  mounted() {
    console.log('mounted table')
    this.calculateSize()
    this.generateDates()
    //add listener to window resize
    window.addEventListener('resize', _.debounce(this.calculateSize, 1000))
  },

  beforeDestroy() {
    //remove listener to window resize
    window.removeEventListener('resize', _.debounce(this.calculateSize, 1000))
  },

  methods: {
    calculateSize() {
      this.$nextTick(() => {
        let height = '100%'
        let width = '100%'
        if (this.noVerticalScroll) {
          height = 'auto'
        } else {
          if (this.height) {
            height = this.height
          } else if (this.tableParentRefName && this.$parent.$refs?.[this.tableParentRefName]) {
            height = this.$parent.$refs?.[this.tableParentRefName]?.clientHeight + 'px'
          }
        }
        if (this.width) {
          width = this.width
        } else if (this.tableParentRefName && this.$parent.$refs?.[this.tableParentRefName]) {
          width = this.$parent.$refs?.[this.tableParentRefName]?.clientWidth + 'px'
        }
        this.componentHeight = height
        this.componentWidth = width
      })
    },

    generateDates() {
      console.log('startDate', this.startDate)
      console.log('endDate', this.endDate)
      let start = this.startDate ? Dates.format(this.startDate, 'YYYY-MM-DD') : `${this.year}-01-01`
      let end = this.endDate ? Dates.format(this.endDate, 'YYYY-MM-DD') : `${this.year}-12-31`
      this.tableDays = this.dateRange(start, end).days.map(el => Dates.formatDateToAPI(el))
      this.$nextTick(() => {
        console.log('innerComponent', this.$refs['innerComponent'])
        this.innerComponentWidth = this.$refs['innerComponent'].offsetWidth
      })
    },

    dateRange(startDate, endDate, steps = 1) {
      const dateArray = []
      const dateByMonth = {}
      let currentDate = new Date(startDate)

      while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate))

        if (currentDate.getUTCMonth() in dateByMonth) {
          // dateByMonth[currentDate.getUTCMonth()].push(new Date(currentDate))
          dateByMonth[currentDate.getUTCMonth()] += 1
        } else {
          // dateByMonth[currentDate.getUTCMonth()] = [new Date(currentDate)]
          dateByMonth[currentDate.getUTCMonth()] = 1
        }
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + steps)
      }

      return { days: dateArray, months: dateByMonth }
    }
  }
}
</script>

<style lang="postcss">
table.table-sticky {
  white-space: nowrap;
  table-layout: fixed;
}

/* Set header to be sticky */
table.table-sticky thead tr:first-child th {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 20vw;
}
table.table-sticky thead tr:first-child th:first-child {
  z-index: 2;
}

table.table-sticky thead tr th {
  position: sticky;
  top: 28px;
  z-index: 1;
  width: 25vw;
}

table.table-sticky tbody th {
  position: relative;
}
/* Set left column to be sticky */
table.table-sticky thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
/* Set left column to be sticky */
table.table-sticky tbody th {
  position: sticky;
  left: 0;
  z-index: 1;
}
</style>
